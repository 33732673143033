import React, { Component } from "react";
import { getFullBodyWorkout } from "../../store/actions/GetWorkOut";
import {
  getAllChallenges,
  setSelectedChallenge,
} from "../../store/actions/Challenge";
import { connect } from "react-redux";
import SliderWorkout from "../Slider/SliderWorkout";
import { Link } from "react-router-dom";
import Back from "../Button/Back";
class Challenges extends Component {
  state = {
    fullBodyWorkout: null,
  };
  backToHome = () => {
    this.props.history.push("/index");
  };
  componentDidMount = async () => {
    try {
      const fullBodyWorkout = await this.props.getFullBodyWorkout();
      await this.props.getAllChallenges();
      this.setState({ fullBodyWorkout });
    } catch (error) {
      console.log(error);
    }
  };
  goToChallengeDetail = (challengeId) => {
    this.props.setSelectedChallenge(challengeId);
    this.props.history.push("/ChallengeDetail");
  };
  render() {
    const { Challenges } = this.props;
    console.log(this.props);
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <Back onClick={this.backToHome} />
        </div>
        <div className="page-content page-content-workout">
          <div className="width_workout_slider">
            <h3 className="title-main">Challenges <span className="textred">NEW!</span></h3>
            <div className="challenges">
              {Challenges && Challenges.length > 0
                ? Challenges.map((item, index) => (
                  <a
                    // to={{
                    //   pathname: `/ChallengeDetail`,
                    //   state: { item },
                    // }}
                    onClick={() => this.goToChallengeDetail(item.id)}
                    className="item"
                    key={index}
                  >
                    <img src={item.thumb} alt="" />
                    <h4>
                      <span className={""} />
                    </h4>
                    <p>{item.name}</p>
                  </a>
                ))
                : null}
            </div>
          </div>
          {this.state.fullBodyWorkout &&
            this.state.fullBodyWorkout.length > 0 ? (
            <>
              <div style={{ marginBottom: 0, paddingLeft: 20, paddingRight: 20, clear: 'both', minHeight: 69 }}>
                <h3 className="title-main">
                  Targeted Workouts
                  <p className="textbellow">
                  Choose a specific part of your body you would like to target.
                  </p>
                </h3>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div style={{ overflowX: 'hidden', paddingBottom: 30,position: 'relative' }}>
                <div style={{ paddingLeft: 20,}}>
                  <SliderWorkout
                    isBodyCategory={true}
                    fullBodyWorkout={this.state.fullBodyWorkout}
                  />
                </div>
              </div>

            </>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.challenge,
  };
};
export default connect(
  mapStateToProps,
  { getFullBodyWorkout, getAllChallenges, setSelectedChallenge }
)(Challenges);
