import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Thai_style_lettuce_quorn_cups extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
         Meals
        </div>

        <div className="recipesMain">
          <div className="image">
            <img src={require("../../../images/recipes/lunch/08.jpg")} alt="" />
          </div>
          <p>
          Thai-Style Lettuce Quorn Cups (V)
            <span> Serves: 4 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            600g Quorn mince (can be substituted with minced meat) <br />
            3 tbsp soy sauce
            <br />
            1 red chilli padi (or 1 tbsp chilli flakes)
            <br />
            2 cloves garlic
            <br />
            2 tsp brown sugar
            <br />
            200g shiitake mushrooms, diced
            <br />
            1 medium onion, diced
            <br />
            1 head lettuce
            <br />
            Juice from half a lemon
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                In a wok or a pan, saute the chopped onion and garlic until
                fragrant, then add the chilli padi or chilli flakes. This amount
                of chilli can be added to your liking.
              </li>
              <li>
                <span>2</span>
                Add the Quorn mince and saute for 5 minutes, then add the diced
                mushrooms and cook until soft.
              </li>
              <li>
                <span>3</span>
                Add in the brown sugar, soy sauce, lemon juice, and extra salt
                to taste. Cook for another few minutes until fully incorporated
                and you can smell the aroma.
              </li>
              <li>
                <span>4</span>
                Use the lettuce as cups to hold the Quorn mixture, and serve.
              </li>
            </ul>
            <div className="note">
              Tip: If you are not vegan, you may wish to substitute 1 tbsp of soy
              sauce with fish sauce.
            </div>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Thai_style_lettuce_quorn_cups;
