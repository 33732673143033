import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { access } from "fs";
class ChallengeSliderProgrammeContent extends Component {
  state = {
    workOutOption: null,
  };
  componentDidMount() {
    const { workoutItem, percent } = this.props;
    if (workoutItem) {
      switch (true) {
        case percent === 100:
          this.setState({ workOutOption: "START AGAIN" });
          break;
        case percent > 0:
          this.setState({ workOutOption: "CONTINUE" });
          break;
        default:
          this.setState({ workOutOption: "START" });
          break;
      }
    }
  }
  render() {
    const { workoutItem, week, percent, isExpired } = this.props;
    let canAccess = false;
    if (parseInt(week) === 1) {
      canAccess = true;
    } else {
      if (!isExpired) {
        canAccess = true;
      }
    }
    return (
      <div>
        {workoutItem ? (
          <div className="item">
            <div className="content_programme">
              <h4>{`Week ${week}`}</h4>
              {!canAccess ? (
                <span className="lock">
                  <img
                    onClick={this.props.onUpgrade}
                    src={require("../../images/lock.svg")}
                    alt=""
                  />
                </span>
              ) : null}
              <div className="name_programme">
                <div>
                  <p>Programme</p>
                  {workoutItem.map((item, index) => (
                    <span key={item.id}>
                      Workout {index + 1}{" "}
                      {item.description ? `- ${item.description}` : ""}
                      {item.historyId && (
                        <img src={require("../../images/check.svg")} alt="" />
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="button">
              <button
                onClick={this.props.onClick.bind(this, week)}
                className={canAccess ? "button-w220" : "button-w220 disable"}
                type="button"
                disabled={!canAccess}
              >
                {percent && percent === 100
                  ? "START AGAIN"
                  : percent > 0
                  ? "CONTINUE"
                  : "START"}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
  };
};
export default connect(
  mapStateToProps,
  {}
)(ChallengeSliderProgrammeContent);
