import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import ChallengeSliderProgrammeContent from "./ChallengeSliderProgrammeContent";
import { getCurrentWorkOut } from "../../store/actions/GetWorkOut";
class ChallengeSliderProgramme extends Component {
  componentDidMount = async () => {
    this.slider.slickGoTo(this.props.index);
  };
  render() {
    const { workoutList, programmeStatus } = this.props;
    console.log({ workoutList });
    const settings = {
      className: "center",
      centerMode: true,
      infinite: false,
      beforeChange: (current, changedIndex) => {
        this.props.onChange(changedIndex);
      },
      centerPadding: "40px",
      slidesToShow: 1,
      speed: 500,
    };
    return (
      <div>
        <div className="programme_slider">
          <Slider ref={(slider) => (this.slider = slider)} {...settings}>
            {workoutList &&
              Object.keys(workoutList).map((key, index) => (
                <ChallengeSliderProgrammeContent
                  week={parseInt(key)}
                  workoutItem={workoutList[key]}
                  key={key}
                  onClick={() => this.props.onClick(key, workoutList[key])}
                  enable={
                    programmeStatus.length > 0 && programmeStatus[index]
                      ? programmeStatus[index].enable
                      : false
                  }
                  onUpgrade={this.props.onUpgrade}
                  percent={
                    programmeStatus.length > 0 && programmeStatus[index]
                      ? programmeStatus[index].percent
                      : 0
                  }
                />
              ))}
          </Slider>
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  { getCurrentWorkOut }
)(ChallengeSliderProgramme);
