import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Spinach_and_shiitake_fritata extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Breakfast
        </div>

        <div className="recipesMain">
          <div className="image">
            <img
              src={require("../../../images/recipes/breakfast/03.jpg")}
              alt=""
            />
          </div>
          <p>
            Spinach and Shiitake Frittata (V, GF)
            <span> Serves: 4 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            3 egg whites <br />
            3 full eggs
            <br />
            60ml milk (1/4 cups)
            <br />
            120g quartered shiitake mushrooms
            <br />
            150g frozen spinach (or any leftover veggies)
            <br />
            1 medium onion, diced
            <br />
            10 sliced cherry tomatoes (optional)
            <br />
            80g Feta cheese, crumbled
            <br />
            <h3 className="title-main">What you need</h3>
            Non-stick pan (or cast iron, oven safe)
            <br />
            Large bowl
            <br />
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                On a large sheet of aluminium foil, lightly drizzle some olive
                oil. Wrap the pumpkin and the tomatoes in the foil, and roast in
                the oven for 30 mins at 350ºF.
              </li>
              <li>
                <span>2</span>
                In a large non-stick pan, saute the onions over medium heat for
                a few minutes and add in the defrosted spinach, shiitake
                mushrooms, and half the tomatoes, and continue cooking for 3-5
                minutes over medium heat.
              </li>
              <li>
                <span>3</span>
                Pour the egg whites into the non-stick pan and immediately turn
                to low heat. Gently stir the mixture in the pan for 30 seconds
                until the egg begins to set but not yet fully cooked. At this
                point, crumble the feta over the frittata, and add the remaining
                tomatoes on top.
              </li>
              <li>
                <span>4</span>
                Cover with a lid and cook on low heat for 5 to 8 minutes.
              </li>
              <li>
                <span>5</span>
                After the egg is cooked and has become slightly puffy, release
                the edges with a spatula or spoon, then slice it into 4 portions
                to serve.
              </li>
            </ul>
            <div className="note">
              *Tip: You can add any leftover veggies you may have! Green peas,
              leafy veg, all work in this recipe.
            </div>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Spinach_and_shiitake_fritata;
