import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonDownload from "../ButtonDownload";

export class Siracha_avocado_toast extends Component {
  render() {
    const { isOnApp } = this.props.match.params;
    return (
      <div
        className="page pageNomal"
        style={{ height: "auto", paddingBottom: 30 }}
      >
        <div className="page-header header-recipes">
          <Link to="#">Tyen's Recipes</Link>
          <img src={require("../../../images/ic_arrow.svg")} alt="" />
          Breakfast
        </div>

        <div className="recipesMain">
          <div className="image">
            <img
              src={require("../../../images/recipes/breakfast/05.jpg")}
              alt=""
            />
          </div>
          <p>
          Sriracha Avocado Toast (V, VG)
            <span> Serves: 2 </span>
          </p>

          <div className="content">
            <h3 className="title-main">Ingredients</h3>
            1 avocado <br />
            Bread
            <br />
            Sesame seeds
            <br />
            <div className="ttchild">For the dressing</div>
            2 tsp soy sauce
            <br />
            1 tsp sesame oil
            <br />
            1 tsp honey
            <br />
            1 tbsp Sriracha
            <br />
            <h3 className="title-main">What you need</h3>
            Something to toast your bread - a toaster oven, oven, or non-stick
            pan
            <h3 className="title-main">Instructions</h3>
            <ul className="instructions">
              <li>
                <span>1</span>
                Combine all the ingredients for the dressing in a small bowl and
                mix
              </li>
              <li>
                <span>2</span>
                Halve the avocados, scoop out the flesh and slice it into thin
                slices
              </li>
              <li>
                <span>3</span>
                Toast your bread and top it with the sliced avocado. Finish with
                a drizzle of the dressing, and finish with sesame seeds.
              </li>
            </ul>
          </div>
        </div>
        {isOnApp !== "true" ? <ButtonDownload /> : null}
      </div>
    );
  }
}

export default Siracha_avocado_toast;
